//FONTS ADD
@import url('https://fonts.googleapis.com/css?family=Yeseva+One&display=swap');

.navbar {
	display: none;
	padding: 0;
}
.btn {
	border-radius: 0;
	border: none;
	&:focus {
		box-shadow: none;
		border-radius: 0;
	}
}
.nav-link {	
	font-family: 'Yeseva One', cursive;
	margin: 0 4px;
	font-size: 1.2rem;
	//padding: 4px 0;
	@media screen and (min-width: 992px) {
		border-bottom: 3px solid transparent;
	}
}
.nav-item.active {
	.nav-link {
		@media screen and (min-width: 992px) {
			border-bottom: 3px solid #000;
		}
	}
}

h1,h2 {
	font-family: 'Yeseva One', cursive;
	font-size: 2.5rem;
}
p {
	font-size: 1.2rem;
}
p > strong {
	font-family: 'Yeseva One', cursive;
	font-size: 1.25rem;
	font-weight: 500;
}

.link-letter {
	font-family: 'Yeseva One', cursive;
	font-size: 1.4rem;
}

.btn-up {
	text-decoration: none;
	position: fixed;
	right: 30px;
	bottom: 30px;
	background-color: #ffffff;
	display: block;
	padding: 15px 20px;
	border-radius: 50%;
	outline: none;
	color: #444444;
	&:hover {
		color: #000000;
	}
	&:hover,&:focus {
		outline: none;
		text-decoration: none;
	}
}